import React from 'react'

import Sedan from '../components/sedan'
import HalfImg from '../components/HalfImg'
import Helmet from 'react-helmet'
import gmap from '../utils/gmap'
import ContactTopBar from '../components/ContactTopBar'
import Layout from './Layout'

function CityPage(props) {
  const city = props.pageContext.city

  return (
    <Layout>
      <div>
        <Helmet
          title={`${city.name} - Sprawdzenie samochodu przed zakupem - AutoTest360`}
        />

        <div className="row img-section" id={'main'}>
          <div className="overlay-effect" />

          <div className="col-12 img-section-content">
            <ContactTopBar />

            <div style={{ justifyContent: 'center' }}>
              <Sedan size={64} />
              <h1>{city.name}</h1>
              <h2>Sprawdzenie samochodu przed zakupem w {city.miejscownik}</h2>
              <p>Już od {`${city.price}zł`}</p>
              <a href={'/'}>
                <div>Zobacz pełną ofertę</div>
              </a>
              <h3 id="miasta" />
            </div>

            <div className="content" />
          </div>
        </div>

        <HalfImg imgId="img-4" full imgRight>
          <iframe
            id="googleMap"
            src={gmap(city.name)}
            frameBorder="0"
            allowFullScreen
          />
        </HalfImg>
      </div>
    </Layout>
  )
}

export default CityPage
